/*.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
*/
button {
    outline: none !important;
}
.nft-slider::-webkit-scrollbar-track {
    display: none;
}

.nft-slider::-webkit-scrollbar {
    display: none;
}

.nft-slider::-webkit-scrollbar-thumb {
    display: none;
}

.side-social-icon {
    padding: 35px 5px;
    background: linear-gradient(60deg, #A18CD1 13.4%, #FBC2EB 86.6%);
    border-radius: 45px 0px 0px 45px;
    display: inline-block;
    position: fixed;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    z-index: 999999;
    transition: all 350ms ease-in-out;
}

    .side-social-icon a {
        padding: 17px 5px 17px 12px;
    }

::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    border-radius: 10px;
    background-color: #F5F5F5;
}

::-webkit-scrollbar {
    width: 6px;
    background-color: #142DA0;
}

::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(120,45,210,1) 100%);
}

body {
    font-family: Orbitron;
    overflow-x: hidden;
}

.main-bg {
    background: url(/public/main-bg.png);
    min-height: 100vh;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;
    padding: 40px 70px;
}

.price-btn {
    width: 100%;
    display: block;
}

.price-btn,
.connect-wallet-btn {
    position: relative;
    display: inline-block;
}

    .price-btn button,
    .connect-wallet-btn button {
        transform: skewX(-30deg);
    }

    .price-btn button,
    .connect-wallet-btn button {
        background: linear-gradient(186.22deg, #F3A6FF 13.62%, #B380F5 86.41%);
        padding: 15px 30px;
        border-radius: 10px 32px 10px 32px;
        position: relative;
        z-index: 9999;
        border: none;
        transition: all 300ms ease-in-out;
        -webkit-transition: all 300ms ease-in-out;
    }

    .connect-wallet-btn:hover:before {
        background: #142DA0;
    }

    .connect-wallet-btn button:hover {
        background: linear-gradient(60deg, #FAD0C4 13.4%, #FBC2EB 86.6%);
    }

    .price-btn:before,
    .connect-wallet-btn:before {
        position: absolute;
        content: "";
        background: #BC50EF;
        border-radius: 10px 32px 10px 32px;
        height: 100%;
        width: 100%;
        right: -16px;
        bottom: -4px;
        z-index: 99;
        transition: all 300ms ease-in-out;
        transform: skewX(-30deg);
    }

    .connect-wallet-btn button span {
        transform: skew(30deg);
        display: inline-block;
        color: #fff;
        font-weight: 900;
        font-size: 37.3726px;
        line-height: 39px;
    }

    .connect-wallet-btn button img {
        width: 95px;
        margin-right: 7px;
        transform: skew(30deg);
    }

.heading-text {
    margin: 0;
    font-weight: 900;
    font-size: 50px;
    line-height: 61px;
    text-align: center;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #FFF060;
    -webkit-text-stroke: 6px rgba(255, 240, 96, 0.3);
}

.price-btn button span {
    transform: skew(30deg);
    display: inline-block;
    color: #fff;
    font-weight: 900;
    font-size: 37.3726px;
    line-height: 39px;
}

.price-btn button {
    background: linear-gradient(200.22deg, #F3A6FF 13.48%, #B380F5 86.55%);
    border-radius: 17px;
    padding: 13px 65px;
    min-width: 100%;
    max-width: 100%;
}

.price-btn:before {
    background: linear-gradient(47.81deg, #B33DEB 13.66%, #DE8FFF 86.36%);
    border-radius: 17px;
    right: -9px;
    bottom: -6px;
}

.about-text {
    font-weight: 900;
    font-size: 24px;
    line-height: 30px;
    text-align: center;
    text-transform: uppercase;
    color: #FFF060;
    margin: 0;
}

.community {
    background: linear-gradient(180deg, #F3A6FF 0%, #B380F5 100%);
    border-radius: 20px;
    padding: 12px 55px;
    display: inline-block;
}

    .community h6 {
        font-weight: 900;
        font-size: 24px;
        text-align: center;
        text-transform: uppercase;
        background: linear-gradient(158.04deg, #FFE856 12.76%, #FBD241 90.21%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    .community > div {
        display: flex;
        justify-content: space-between;
        margin-top: 10px;
        align-items: center;
    }

.price-btn.mint-btn:before {
    background: #BC50EF;
}

.price-btn.mint-btn:before,
.price-btn.mint-btn {
    -webkit-transition: all 300ms ease-in-out;
}

    .price-btn.mint-btn button span {
        transform: skewX(40deg);
    }

    .price-btn.mint-btn button {
        background: linear-gradient(186.22deg, #F3A6FF 13.62%, #B380F5 86.41%);
    }

        .price-btn.mint-btn button:hover {
            background: linear-gradient(89.32deg, #FAD0C4 2.01%, #FBC2EB 114%);
        }

    .price-btn.mint-btn:hover:before {
        background: #142DA0;
    }

span {
    cursor: pointer;
}

.number {
    display: flex;
}

.minus, .plus {
    position: relative;
    display: inline-block;
    transform: skewX(-30deg);
}

    .plus button,
    .minus button {
        background: linear-gradient(186.22deg, #F3A6FF 13.62%, #B380F5 86.41%);
        padding: 15px 22px;
        border-radius: 17px;
        position: relative;
        z-index: 9999;
        border: none;
        transition: all 300ms ease-in-out;
        -webkit-transition: all 300ms ease-in-out;
    }

    .minus:before, .plus:before {
        position: absolute;
        content: "";
        background: linear-gradient(17.92deg, #B33DEB 14.33%, #DE8FFF 85.73%);
        border-radius: 17px;
        height: 100%;
        width: 100%;
        right: -8px;
        bottom: -4px;
        z-index: 99;
        transition: all 300ms ease-in-out;
    }

    .plus button span,
    .minus button span {
        transform: skew(30deg);
        display: inline-block;
    }

.number .number-input {
    position: relative;
    display: inline-block;
    transform: skewX(-30deg);
}

.price-btn.mint-btn:before,
.price-btn.mint-btn button {
    transform: skewX(-40deg);
    border-radius: 20px;
}

.number .number-input:before {
    position: absolute;
    content: "";
    background: linear-gradient(186.22deg, #F3A6FF 13.62%, #B380F5 86.41%);
    border-radius: 17px;
    height: 100%;
    width: 100%;
    right: -9px;
    bottom: -5px;
    z-index: 99;
    transition: all 300ms ease-in-out;
}

.number .number-input span {
    background: linear-gradient(158.04deg, #FFE856 12.76%, #FBD241 90.21%);
    display: inline-block;
    height: 69px;
    font-weight: 900;
    padding: 15px 20px;
    border-radius: 17px;
    position: relative;
    z-index: 9999;
    border: none;
}

.number input {
    display: inline-block;
    font-size: 44px;
    font-weight: bolder;
    height: 100%;
    width: 100%;
    border: none;
    transform: skewX(30deg);
    /*color: #B380F5;*/
    outline: none !important;
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: #B23DEB;
    background: linear-gradient(186.22deg, #F3A6FF 13.62%, #B380F5 86.41%);
    /*text-shadow: 3.5px 2px 0px #B23DEB;*/
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

#minutes,
#hours,
#seconds {
    /* min-width: 86px; */
    display: inline-block;
}

.plus-hover-icon,
.minus-hover-icon {
    display: none;
}

.minus button:hover,
.plus button:hover {
    background: linear-gradient(60deg, #FAD0C4 13.4%, #FBC2EB 86.6%);
}

    .minus button:hover .minus-icon,
    .plus button:hover .plus-icon {
        display: none;
    }

    .minus button:hover .minus-hover-icon,
    .plus button:hover .plus-hover-icon {
        display: inline-block;
    }

.second-one {
    margin: 35px 0;
}

.box-image {
    top: 0%;
    animation: box-floating 3.5s infinite;
    animation-timing-function: linear;
    animation-direction: alternate;
}

@keyframes box-floating {
    0% {
        top: 0%;
    }

    100% {
        top: 7%;
    }
}

@media(max-width:991px) {
    .logo {
        width: 85%;
    }

    .main-bg {
        background: url(/public/mobile-bg.png);
        padding: 20px;
        height: unset;
        height: unset;
        background-repeat: no-repeat;
        background-size: cover;
    }

    .connect-wallet-btn button span {
        font-weight: 900;
        font-size: 21.8152px;
    }

    .connect-wallet-btn {
        margin-top: 13px;
    }

        .price-btn, .connect-wallet-btn,
        .price-btn button, .connect-wallet-btn button {
            width: 100%;
        }

            .connect-wallet-btn button {
                padding: 5px 30px;
                border-radius: 10px 15px 10px 15px;
            }

            .connect-wallet-btn:before {
                border-radius: 10px 15px 10px 15px;
                right: -6px;
            }

    .heading-text {
        font-weight: 900;
        font-size: 27px;
        line-height: 31px;
    }

    .box-image {
        max-width: 100% !important;
        margin-right: 10px !important;
    }

    .price-btn button span {
        font-weight: 900;
        font-size: 17.91px;
        line-height: 20px;
    }

    .price-btn button {
        padding: 8px 5px;
        border-radius: 8px;
    }

    .number .number-input span {
        height: 40.87px;
        padding: 0px 10px;
        border-radius: 8px;
    }

    .plus button, .minus button {
        padding: 8px 10px;
        border-radius: 8px;
    }

    .number input {
        font-size: 24px;
        text-shadow: none;
        -webkit-text-stroke-width: 1px;
    }

    .number .number-input:before,
    .minus:before, .plus:before,
    .price-btn:before {
        border-radius: 8px;
        right: -4px;
        bottom: -4px;
    }

    .number span img {
        width: 18px;
    }

    .second-one {
        margin: 15px 0;
    }



    .price-btn.mint-btn button {
        padding: 11px 10px;
        background: linear-gradient(186.22deg, #F3A6FF 13.62%, #B380F5 86.41%);
        border-radius: 13px;
    }

    .price-btn.mint-btn:before {
        border-radius: 13px;
    }

    .community h6 {
        font-size: 18px;
        line-height: 19px;
        margin: 0;
    }

    .community {
        padding: 10px 10px;
        display: flex;
        align-items: center;
        margin-top: 28px;
        justify-content: space-between;
    }

        .community div a:nth-child(2) {
            margin: 0 15px;
        }

        .community img {
            width: 50px;
        }

    .about-text {
        font-size: 14px;
        line-height: 16px;
        margin-top: 10px;
    }

    .community > div {
        margin: 0;
    }
}

@media(max-width:372px) {
    .heading-text {
        font-weight: 900;
        font-size: 22px;
        line-height: 31px;
    }

    .connect-wallet-btn button span {
        font-size: 18px;
    }

    .community {
        flex-wrap: wrap;
        justify-content: center;
    }
}

@media(max-width:575px) {

    #minutes, #hours, #seconds {
        min-width: unset;
    }

    .box-image {
        top: -10%;
        animation: box-floating 3.5s infinite;
        animation-timing-function: linear;
        animation-direction: alternate;
    }

    @keyframes box-floating {
        0% {
            top: -10%;
        }

        100% {
            top: 3%;
        }
    }
}

@media(min-width:576px) and (max-width:767px) {
    .box-image {
        max-width: 60% !important;
        margin-right: 10px !important;
    }

    .number-input span,
    .number-input {
        width: 100%;
    }

    .main-bg {
        padding: 50px 30px;
        min-height: 100vh;
    }

    #minutes, #hours, #seconds {
        min-width: 52px;
    }
}

@media(min-width:768px) and (max-width:1199px) {

    .main-bg {
        padding: 50px 30px;
        min-height: 100vh;
    }

    .connect-wallet-btn button {
        justify-content: center;
    }

    .heading-text {
        font-size: 27px;
    }

    #minutes, #hours, #seconds {
        min-width: 52px;
    }
}

@media(min-width:992px) and (max-width:1499px) {
    .logo {
        width: 85%;
    }

    #minutes,
    #hours,
    #seconds {
        min-width: 58px;
    }

    .heading-text {
        font-weight: 900;
        font-size: 32px;
        line-height: 40px;
    }

    .box-image {
        max-width: 100% !important;
        margin-right: 10px !important;
    }

    .price-btn button span {
        font-size: 28px;
    }

    .price-btn button {
        padding: 5px 10px;
        border-radius: 8px;
    }

    .number .number-input span {
        height: 49px;
        padding: 5px 10px;
        border-radius: 8px;
    }

    .plus button, .minus button {
        padding: 13px 20px;
        border-radius: 8px;
    }

    .number input {
        font-size: 28px;
        text-shadow: none;
    }

    .number .number-input:before,
    .minus:before, .plus:before,
    .price-btn:before {
        border-radius: 8px;
        right: -5px;
        bottom: -4px;
    }

    .number span img {
        width: 22px;
    }

    .second-one {
        margin: 25px 0;
    }

    .price-btn.mint-btn button {
        padding: 10px 10px;
    }

    .community h6 {
        font-size: 20px;
        line-height: 19px;
        margin: 0;
    }

    .community {
        padding: 12px 30px;
        margin-top: 17px;
        text-align: center;
    }

        .community img {
            width: 70px;
        }
}

@media(min-width:1500px) {
    .plus button span, .minus button span {
        line-height: 38px;
    }
}


/*LANDING-PAGE-CSS-START*/
.landing-bg {
    background: url(/public/landing-page-bg.png);
    background-size: cover;
    background-repeat: no-repeat;
}

.menu-navbar {
    padding: 50px 80px 50px 50px;
}

.menu-nav .navbar-nav {
    display: flex;
    flex-direction: row;
}

    .menu-nav .navbar-nav li.nav-item {
        position: relative;
        transition: all 500ms linear;
    }
.footer-last{
    padding-right:100px;
}

.dropdown {
    position: absolute;
    padding: 0;
    list-style: none;
    width: 200px;
    text-align: center;
    display: inline-block;
    opacity: 0;
    transition: all 450ms ease-in-out;
    -webkit-transition: all 450ms ease-in-out;
    top: -100px;
    left: -22px;
}

.menu-nav .navbar-nav li.nav-item:hover .dropdown {
    opacity: 1;
    top: 40px
}

.dropdown li a {
    position: relative;
    display: inline-block;
    padding: 0 10px;
}

    .dropdown li a:before {
        transform: skewX(-40deg);
        background: transparent;
        height: 100%;
        content: "";
        position: absolute;
        left: -20px;
        top: 0px;
        z-index: 99;
        width: 100%;
        border-radius: 5px 10px 5px 10px;
        transition: all 0.5s linear;
    }

    .dropdown li a span {
        padding: 0;
        position: relative;
        font-weight: 900;
        font-size: 20px;
        line-height: 1;
        text-align: center;
        color: #FFFFFF;
        text-transform: uppercase;
        display: inline-block;
        z-index: 999;
        padding: 3px 0;
        width: 100%;
        cursor: pointer;
    }

    .dropdown li a:hover:before {
        background: linear-gradient(180deg, #A6E8FF 0%, rgba(179, 128, 245, 0) 69%);
        left: -5px;
    }

.menu-nav .navbar-nav li.nav-item > a {
    padding: 0;
    transform: skewX(-40deg);
    font-weight: 900;
    font-size: 20px;
    text-align: center;
    color: #FFFFFF;
    text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.15);
    text-transform: uppercase;
    background: transparent;
    outline: 5px solid transparent;
    padding: 5px 0;
    width: 150px;
    border-radius: 5px 10px 5px 10px;
    cursor: pointer;
}

    .menu-nav .navbar-nav li.nav-item > a:hover {
        background: linear-gradient(60deg, #FAD0C4 13.4%, #FBC2EB 86.6%);
        outline: 5px solid rgba(255, 255, 255, 0.2);
    }

    .menu-nav .navbar-nav li.nav-item > a span {
        transform: skewX(40deg);
        display: inline-block;
    }

.playnow-btn {
    position: relative;
    display: inline-block;
    margin-left: 30px;
    z-index: 9999;
}

    .playnow-btn button,
    .playnow-btn a {
        background: #E19BFC;
        padding: 8px 50px;
        border-radius: 20px;
        position: relative;
        display: inline-block;
        z-index: 9999;
        border: none;
        transition: all 400ms ease-in-out !important;
        transform: skewX(-40deg);
        /*-webkit-transition: all 1050ms ease-in-out !important;*/
    }


    .playnow-btn:before {
        position: absolute;
        content: "";
        background: #BC50EF;
        border-radius: 20px;
        height: 100%;
        width: 100%;
        display: inline-block;
        right: -6px;
        top: 6px;
        z-index: 99;
        transition: all 400ms ease-in-out;
        transform: skewX(-40deg);
    }

    .playnow-btn:hover:before {
        background: #142DA0;
    }

    .playnow-btn button:hover,
    .playnow-btn a:hover {
        background: #FBC4E3;
    }

    .playnow-btn button span,
    .playnow-btn a span {
        transform: skew(40deg);
        font-weight: 900;
        font-size: 32px;
        text-align: center;
        display: inline-block;
        text-transform: uppercase;
        color: #FFFFFF;
    }

.slider {
    height: calc(100vh - 164px);
}

.slider-text img.slider-logo {
    margin-bottom: 50px;
}

.slider-text p {
    font-weight: 800;
    font-size: 18px;
    line-height: 20px;
    letter-spacing: 0.01em;
    color: #FFFFFF;
    margin: 0;
}

.slider-text h6 {
    font-style: normal;
    font-weight: 800;
    font-size: 18px;
    line-height: 20px;
    display: inline-block;
    margin-top: 15px;
    margin-bottom: 15px;
    color: #00FFE0;
}

.slider-text h5 {
    font-weight: 900;
    font-size: 18px;
    color: #FFFFFF;
    margin-top: 40px;
}

.slider-text .playnow-btn {
    margin: 0;
    width: 90%;
    margin-top: 15px;
}

.slider-gif {
    width: 936.47px;
    /* height: 643px; */
    height: 534px;
    width: auto;
    height: auto;
    overflow: hidden;
    border: 10px solid #FBC4E6;
    border-radius: 50px;
    text-align: center;
}

.vid-iframe{
    width: 100%;
    height: 100%;
}

    .slider-gif img {
        width: 100%;
        /* height: 100%; */
        height: 58%;
    }

.game-play {
    padding: 220px 0;
    position: relative;
}

    .game-play:before {
        position: absolute;
        content: "";
        height: 100%;
        width: 100%;
        background: url(/public/overlay.png);
        background-size: cover;
        mix-blend-mode: lighten;
        top: 0;
        z-index: 9;
    }

.section-heading {
    font-weight: 900;
    font-size: 64.6108px;
    text-align: center;
    text-transform: uppercase;
    color: #FFF060;
    position: relative;
    z-index: 99;
    margin-bottom: 0px;
    -webkit-text-stroke: 1.35px #fff060;
}

.subheading-text, .subheading-text-2 {
    font-weight: 900;
    font-size: 18px;
    line-height: 25px;
    text-align: center;
    color: #FFFFFF;
    width: 50%;
    margin: 0 auto;
    position: relative;
    z-index: 99;
}

.gameplay-slider {
    padding: 0 30px;
    position: relative;
    z-index: 99;
}

    .gameplay-slider:before {
        content: "";
        position: absolute;
        right: 0px;
        width: 954px;
        height: 448.85px;
        background: linear-gradient(71.37deg, rgba(251, 201, 217, 0) 11.31%, rgba(250, 202, 214, 0.125) 11.32%, rgba(250, 208, 196, 0.6) 75.46%);
        mix-blend-mode: normal;
        filter: blur(80px);
    }

    .gameplay-slider .carousel-item h3 {
        font-weight: 900;
        font-size: 36px;
        line-height: 24px;
        color: #0BFFF0;
        margin-top: 30px;
    }

    .gameplay-slider .carousel-item p {
        font-weight: 900;
        font-size: 18px;
        line-height: 24px;
        color: #0BFFF0;
        width: 60%;
        margin: auto;
    }

    .gameplay-slider .carousel-item .playnow-btn {
        margin-top: 40px;
        margin-left: 0px;
    }

        .gameplay-slider .carousel-item .playnow-btn button,
        .gameplay-slider .carousel-item .playnow-btn a {
            padding: 6px 60px;
        }

.gameplay-slider-btn.playnow-btn button span,
.gameplay-slider-btn.playnow-btn a span {
    font-size: 24.2963px;
}

.gameplay-slider-btn.playnow-btn {
    position: absolute;
    margin: 0;
}

    .gameplay-slider-btn.playnow-btn.prev {
        left: 5%;
        bottom: -20%;
    }

    .gameplay-slider-btn.playnow-btn.next {
        right: 5%;
        bottom: -20%;
    }

    .gameplay-slider-btn.playnow-btn:before {
        border-radius: 15px;
    }

    .gameplay-slider-btn.playnow-btn a,
    .gameplay-slider-btn.playnow-btn button {
        padding: 7px 0;
        width: 240px;
        border-radius: 15px;
        margin: 0;
        text-align: center;
    }

.gameplay-slider .carousel-indicators {
    position: absolute;
    right: unset;
    left: 50%;
    transform: translateX(-50%);
    bottom: -20%;
    z-index: 2;
    display: unset;
    justify-content: center;
    padding: 0;
    margin-right: unset;
    margin-bottom: unset;
    margin-left: unset;
}

    .gameplay-slider .carousel-indicators .active {
        background: #0EC7D2 !important;
        box-shadow: 4px 4px 0px #09797B !important;
    }

    .gameplay-slider .carousel-indicators [data-bs-target] {
        background: linear-gradient(60deg, #FAD0C4 13.4%, #FBC2EB 86.6%);
        box-shadow: 4px 4px 0px #C445AB;
        border-radius: 5px;
        height: 6px;
        opacity: 1;
        margin: 0 20px;
        width: 150px;
    }

.gameplay-slider .carousel-fade .carousel-item {
    transition: all 450ms ease-in-out;
}

#inviteCode.invite-page {
    position: relative;
    width: 95%;
    border-radius: 13.7531px;
}

    #inviteCode.invite-page:before {
        position: absolute;
        content: "";
        background: #000000;
        mix-blend-mode: normal;
        opacity: 0.1;
        height: 100%;
        width: 100%;
        padding: 5px;
        border-radius: 28px;
    }

    #inviteCode.invite-page:after {
        position: absolute;
        content: "BEP20";
        font-weight: 800;
        font-size: 18px;
        color: #FFFFFF;
        left: 13px;
        top: 50%;
        padding-right: 8px;
        transform: translateY(-50%);
        border-right: 1.83374px solid rgba(0, 0, 0, 0.1);
    }

    #inviteCode.invite-page #mobile_link,
    #inviteCode.invite-page #link {
        background: transparent;
        border: none;
        outline: none;
        font-weight: 800;
        font-size: 18px;
        align-items: center;
        width: 100%;
        padding: 7px;
        display: inline-block;
        word-break: break-all;
        padding-left: 100px;
        position: relative;
        z-index: 999;
        padding-right: 35px;
        color: #FFFFFF;
    }



    #inviteCode.invite-page #copy {
        right: 13px;
        top: 50%;
        transform: translateY(-50%);
        position: absolute;
        cursor: pointer;
    }

.genesis {
    padding: 200px 0;
}

.nft-slider {
    padding: 0 100px;
}

    .nft-slider .gameplay-slider-btn.playnow-btn.prev {
        left: 2%;
        bottom: -12%;
    }

    .nft-slider .gameplay-slider-btn.playnow-btn.next {
        right: 2%;
        bottom: -12%;
    }

    .nft-slider.gameplay-slider .carousel-indicators {
        bottom: -10%;
    }

.nft-border {
    /*background: url(/public/nft-frame.png);*/
    height: 316px;
    width: 340px;
    background-clip: border-box;
    position: relative;
    background-size: cover!important;
}
.HoverNftBorder {
    opacity: 0;
    transition: all 300ms ease-in-out;
}

.nft-border:hover .HoverNftBorder {
    opacity:1;
    z-index:999!important;
}

    .nft-border img:first-child{
        z-index:99!important;
    }
    .nft-border img {
        position: absolute;
        left: 50%;
        transform: translate(-50%,-50%);
        z-index: -9;
        top: 50%;
    }

.mint-now {
    margin-top: -65px;
}

.genesis .gameplay-slider:before {
    display: none;
}

.icc-coin {
    padding: 100px 0;
    position: relative;
    z-index: 99;
}

.paly-to-earn {
    padding: 0 130px;
}

.play-to-earn-btn {
    background: linear-gradient(210deg, #F3A6FF 13.4%, #B380F5 86.6%);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 50px;
    outline: 10px solid rgba(255, 255, 255, 0.1) !important;
    border: none;
    padding: 17px 20px;
    position: relative;
}

    .play-to-earn-btn:before {
        position: absolute;
        content: "";
        height: 1px;
        width: 74px;
        border: 2px dashed rgba(255, 255, 255, 0.6);
        right: -84px;
        top: 50%;
        transform: translateY(-50%);
    }

.paly-to-earn .playnow-btn a,
.paly-to-earn .playnow-btn button {
    padding: 8px 40px;
}

.exchange-btn .play-to-earn-btn {
    padding: 10px 15px;
    width: 100%;
    text-align: left;
    position: relative;
}

.exchange-btn-main {
    position: relative;
}

    .exchange-btn-main:before {
        position: absolute;
        content: "";
        border: 2px dashed rgba(255, 255, 255, 0.6);
        height: 71.5%;
        top: 50%;
        transform: translateY(-50%);
        left: -92px;
    }

.exchange-btn .play-to-earn-btn:before {
    position: absolute;
    content: "";
    height: 1px;
    width: 50px;
    border: 2px dashed rgba(255, 255, 255, 0.6);
    left: -100px;
    top: 50%;
    transform: translateY(-50%);
}

.exchange-btn:nth-child(2) .play-to-earn-btn:before {
    width: 130px;
    left: -180px;
}

.exchange-btn:nth-child(2) {
    margin: 30px 0;
}

.gift-img {
    margin-top: -33px;
}

.coin-image {
    position: relative;
    padding: 150px 0;
}

.coin {
    top: 0px;
    position: relative;
    animation: coin 3.5s infinite;
    animation-timing-function: linear;
    animation-direction: alternate;
}

@keyframes coin {
    0% {
        top: 0px;
    }

    50% {
        top: 30px;
    }

    100% {
        top: -10px;
    }
}

.coin-image:before {
    position: absolute;
    content: "";
    background: url(/public/coin-bg-light.png);
    height: 461px;
    top: 43px;
    left: -150px;
    z-index: -9;
    width: 812px;
}

.core-team:before {
    position: absolute;
    content: "";
    height: 1080px;
    width: 100%;
    background: url(/public/overlay-1.png);
    background-size: cover;
    mix-blend-mode: lighten;
    top: 0;
    z-index: -9;
    left: 0;
    opacity: 0.5;
}

.core-team {
    padding: 200px 130px;
    position: relative;
    z-index: 99;
}

    .core-team p {
        width: 73%;
    }

.core-team-border {
    position: relative;
    z-index: 9999;
}

    .core-team-border .HoverCoreImage {
        position: absolute;
        left: 0;
        top: 0;
        opacity: 0;
        transition: all 350ms ease-in-out;
    }

    .core-team-border:hover .HoverCoreImage {
        z-index: 999;
        opacity: 1;
    }

.core-team-details {
    text-align: center;
    margin-top: 17px;
    padding-right: 52px;
}

    .core-team-details h6 {
        font-weight: 900;
        font-size: 24.6108px;
        text-transform: uppercase;
        color: #FFF060;
        margin-bottom: 5px;
    }

    .core-team-details span {
        font-weight: 900;
        font-size: 19px;
        text-transform: uppercase;
        color: #FFFFFF;
        display: inline-block;
    }

.roadmap-coin-main {
    padding: 300px 70px 270px;
    overflow: hidden;
}

.roadmap-coin {
    margin-top: -150px;
    opacity: .4;
}

.phase-details {
    position: relative;
    left: 315px;
    bottom: -10px;
    z-index: 99;
}

.PhaseHoverImage {
    opacity: 0;
    transition: all 300ms ease-in-out;
    -webkit-transition: all 300ms ease-in-out;
    position: absolute !important;
    z-index: 999;
    left: 0;
}

.phase-images:hover .PhaseHoverImage {
    opacity: 1;
}





.phase-images {
    position: relative;
    z-index: 99;
}

    .phase-images:before {
        position: absolute;
        content: "";
        border: 1px dashed #FFE856;
        height: 80px;
        left: 50%;
        transform: translateX(-50%);
        top: -65%;
        z-index: 9;
    }

    .phase-images:after {
        position: absolute;
        content: "";
        background: url(/public/phase-line.png);
        width: 271px;
        height: 117px;
        z-index: -9;
        top: -55px;
        left: 50px;
    }

.phase-details span {
    font-weight: 800;
    font-size: 24px;
    line-height: 30px;
    text-transform: uppercase;
    color: #FFF060;
    -webkit-text-stroke: 5px rgba(255, 240, 96, 0.3);
    margin-bottom: 80px;
    margin-left: 52px;
    display: inline-block;
}

.phase-details p {
    font-weight: 800;
    font-size: 15px;
    line-height: 19px;
    text-transform: uppercase;
    color: #FFF060;
    width: 45%;
    margin: 10px 0 0 35px;
}

.phase-1 {
    position: relative;
    z-index: 99;
}

.phase-2 {
    position: relative;
    top: -98px;
    left: -67px;
    z-index: 999;
}

.phase-3 {
    position: relative;
    top: -198px;
    left: -157px;
    z-index: 999;
}

.phase-4 {
    position: relative;
    top: -296px;
    left: -227px;
    z-index: 999;
}

.phase-5 {
    position: relative;
    top: -396px;
    left: -307px;
    z-index: 999;
}

    .phase-5:last-child .phase-images:after {
        display: none;
    }

    .phase-5:last-child .phase-images:before {
        height: 192px;
        top: -158%
    }

    .phase-5 span.to-the-moon {
        transform: rotate(-90deg);
        margin-left: -69px;
    }

.phase-arrow {
    position: absolute;
    display: inline-block;
    width: 0;
    height: 0;
    top: -37%;
    left: 1.2%;
    transform: translateX(-50%);
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 12px solid #FFE856;
    z-index: 99999;
}

.moon-man {
    position: absolute;
    top: -37%;
    right: 43%;
}

footer {
    padding: 0 0px 60px 80px;
}

.footer-menu ul {
    list-style: none;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 0 60px;
    justify-content: space-between;
    position: relative;
}

.footer-menu.ul-border ul:before {
    position: absolute;
    content: "";
    height: 242px;
    width: 2px;
    right: 0;
    background: url(/public/footer-border.png);
}

.footer-menu ul li {
    position: relative;
    width: 240px;
    height: 60px;
}

    .footer-menu ul li a {
        font-weight: 900;
        font-size: 24px;
        text-decoration:none!important;
        display: block;
        text-transform: uppercase;
        color: #0BFFF0 !important;
        position: relative;
        text-align: center;
        background: transparent;
    }

    .footer-menu ul li:before {
        position: absolute;
        content: "";
        top: -10px;
        left: 0;
        background: url(/public/footer-menu-border.png);
        height: 100%;
        width: 100%;
        background-size: cover;
        background-repeat: no-repeat;
    }

.footer-community {
    padding: 12px 40px;
}

    .footer-community img {
        width: 90%;
    }

    .footer-community h6 {
        font-size: 23px;
    }

.footer-btn.playnow-btn {
    width: 58%;
    margin-left: 0px;
}

    .footer-btn.playnow-btn a,
    .footer-btn.playnow-btn button {
        padding: 9px 50px;
        width: 100%;
    }

.last-btn .playnow-btn:before {
    border-radius: 15px;
}

.last-btn .playnow-btn a {
    padding: 7px 36px;
    border-radius: 15px;
    width: 251px;
    text-align:center;
}

    .last-btn .playnow-btn a span {
        font-size: 25px;
    }

.coin-price {
    display: inline-block;
    padding: 2px 30px 2px 110px;
    background: linear-gradient(210deg, #A6E8FF 13.4%, #B380F5 86.6%);
    /*border: 3px solid rgba(255, 255, 255, 0.1);*/
    border-radius: 50px;
    font-weight: 900;
    font-size: 32px;
    position: relative;
    text-transform: uppercase;
    color: #FFFFFF!important;
    text-decoration:none!important;
    -webkit-text-stroke: 5px rgba(255, 255, 255, 0.2);
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

    .coin-price img {
        position: absolute;
        left: 7%;
        bottom: 11px;
    }

.phase-details-box {
    display: none;
}
/*LANDING-PAGE-CSS-END*/

@media(max-width:767px) {
    .nft-slider .row {
        width: 2170px;
        margin:0 70px ;
    }
    .core-team-border .HoverCoreImage {
        left: 50%;
        transform: translate(-50%,-50%);
        top: 50%;
    }

    .tooltip .tooltiptext {
        background-color: rgba(0,0,0,.5) !important;
        left: -100% !important;
    }

        .tooltip .tooltiptext::after {
            border-color: rgba(0,0,0,.5) transparent transparent transparent;
        }

    .slider-gif iframe {
        border-radius: 30px;
    }

    #inviteCode.invite-page:before {
        opacity: 0.2;
    }

    .phase-details-box {
        display: block;
    }

    .genesis {
        padding: 100px 0;
    }

    .slider {
        height: auto;
        padding: 20px 0 100px 0;
    }

    .slider-text p {
        font-size: 13px;
        width: 100%;
        margin: 0 auto;
    }
    .slider-first-text {
        width: 100%!important;
    }

    .game-play .subheading-text {
        width: 90% !important;
    }

    .landing-bg {
        background: url(/public/mobile-landing-page-bg.png);
        background-size: cover;
        background-repeat: no-repeat;
    }

    .side-social-icon {
        display: none;
    }

    .menu-navbar.desktop {
        display: none;
    }

    .menu-navbar.mobile .menu-nav {
        width: 100%;
    }

        .menu-navbar.mobile .menu-nav .navbar-nav {
            width: 100%;
            justify-content: space-around;
            padding: 3px 10px;
        }

    .menu-navbar.mobile {
        position: fixed;
        bottom: 18px;
        left: 20px;
        z-index: 99999;
        right: 20px;
        background: linear-gradient(180deg, #F3A6FF 0%, #B380F5 100%);
        border-radius: 10px;
    }

        .menu-navbar.mobile li.nav-item a {
            width: unset;
            transform: skewX(0deg);
            font-size: 13px;
        }

            .menu-navbar.mobile li.nav-item a span {
                transform: skewX(0deg);
                display: block;
            }

    .menu-navbar {
        padding: 0;
    }

    .slider-gif {
        width: 100%;
        /* height: 192px; */
        height: 58%;
        border-radius: 30px;
        border: 5px solid #FBC4E6;
    }

    .slider-logo {
        margin-top: 0px;
    }

    .slider-text img.slider-logo {
        margin-bottom: 5px;
        width:70%;
    }

    .playnow-btn a 
    .playnow-btn button {
        padding: 8px 50px;
    }

    .footer-btn.playnow-btn a span,
    .last-btn .playnow-btn a span,
    .footer-btn.playnow-btn button span,
    .last-btn .playnow-btn button span {
        font-size: 23px !important;
    }

    #inviteCode.invite-page:after,
    #inviteCode.invite-page #mobile_link,
    #inviteCode.invite-page #link {
        font-size: 12px;
    }

    #inviteCode.invite-page #mobile_link,
    #inviteCode.invite-page #link {
        padding-left: 70px;
        padding-right: 38px;
    }

    #inviteCode.invite-page {
        width: 100%;
    }

    .slider-text h5 {
        margin-top: 10px;
        font-size: 16px;
    }

    .slider-text .playnow-btn {
        margin-top: 10px;
    }

    .game-play {
        padding: 50px 0;
        position: relative;
    }

    .section-heading {
        font-size: 30px;
        letter-spacing: 0.04em;
        margin-bottom: 5px;
    }

    .subheading-text,.subheading-text-2  {
        width: 83% !important;
        font-size: 18px;
        letter-spacing: -0.02em;
        line-height: 19px;
    }

    .core-team .subheading-text {
        width: 96% !important;
    }

    .icc-coin .subheading-text {
        width: 92% !important;
    }

    .icc-coin .subheading-text-2 {
        width: 96% !important;
        font-size: 16px;
    }
    .slider-text h6 {
        margin-top: 10px;
        margin-bottom: 10px;
        font-size: 16px;
    }

    .gameplay-slider:before,
    .game-play:before {
        display: none;
    }

    .gameplay-slider .carousel-item h3 {
        text-align: center;
    }

    .gameplay-slider .carousel-item p {
        font-weight: 900;
        font-size: 17px;
        line-height: 19px;
        color: #0BFFF0;
        width: 70%;
        margin: auto;
    }

    .gameplay-slider {
        padding: 0 20px;
    }

        .gameplay-slider .carousel-item p {
            width: 100%;
            text-align: center;
            margin-top: 20px;
        }

        .gameplay-slider .carousel-item .playnow-btn a,
        .gameplay-slider .carousel-item .playnow-btn button {
            padding: 8px 60px;
            width: 100%;
        }

        .gameplay-slider .carousel-item .playnow-btn {
            margin-top: 80px;
            width: 88%;
            margin-left: 0px;
        }

    .prev-icon,
    .next-icon {
        background: transparent;
        border: none;
        padding: 0;
    }

    .gameplay-slider-btn {
        position: absolute;
        margin: 0;
        z-index: 999;
    }

        .gameplay-slider-btn.prev {
            left: 0%;
            bottom: 18%;
        }

        .gameplay-slider-btn.next {
            right: 0%;
            bottom: 18%;
        }

    .gameplay-slider .carousel-indicators [data-bs-target] {
        height: 1px;
        margin: 0 10px;
        width: 70px;
    }

    .gameplay-slider .carousel-indicators {
        position: absolute;
        right: unset;
        width: 100%;
        text-align: center;
        left: 50%;
        transform: translateX(-50%);
        bottom: 20%;
        z-index: 2;
    }

    .mint-now {
        margin: 15px 0 0;
    }

        .mint-now .playnow-btn a,
        .mint-now .playnow-btn button {
            width: 100%;
        }

        .mint-now .playnow-btn {
            margin: 0;
            width: 90%;
        }

    .nft-slider {
        padding: 0 0;
    }

        .nft-slider.gameplay-slider .carousel-indicators,
        .gameplay-slider-btn.playnow-btn.next,
        .gameplay-slider-btn.playnow-btn.prev {
            display: none;
        }

    .coin {
        z-index: 9;
    }

    @keyframes coin {
        0% {
            top: 0px;
        }

        50% {
            top: 5px;
        }

        100% {
            top: -15px;
        }
    }

    .paly-to-earn {
        padding: 0;
    }

    .P2E-text {
        width: 50%;
    }

    .play-to-earn-btn {
        background: linear-gradient(60deg, #FAD0C4 13.4%, #FBC2EB 86.6%);
        border-radius: 10px;
        outline: 5px solid rgba(255, 255, 255, 0.1) !important;
        border: none;
        padding: 5px;
        position: relative;
        width: auto;
    }

        .play-to-earn-btn:before {
            position: absolute;
            content: "";
            height: 30px;
            width: 1px;
            border: 1px dashed rgba(255, 255, 255, 0.6);
            right: 50%;
            bottom: -30%;
            top: unset;
            transform: translateX(-50%);
        }

    .coin img {
        width: 32%;
    }

    .coin-image {
        position: relative;
        padding: 30px 0;
    }

    .exchange-btn-main {
        display: flex;
        justify-content: center;
    }

    .exchange-btn:nth-child(2) {
        margin: 0 15px;
    }

    .exchange-btn .play-to-earn-btn {
        padding: 5px;
        text-align: center;
        height: 130px;
        width: 110px;
    }

    .gift-img {
        margin-top: -20px;
    }

    .trade-img {
        margin-top: 0px;
    }

    .exchange-btn-main .play-to-earn-btn img:first-child {
        width: 100%;
    }

    .exchange-btn-main .play-to-earn-btn img:nth-child(2) {
        width: 100%;
        margin: 0 !important;
    }

    .exchange-btn .play-to-earn-btn:before {
        position: absolute;
        content: "";
        height: 100px;
        width: 1px;
        border: 1px dashed rgba(255, 255, 255, 1);
        left: 50%;
        top: -100px;
        transform: translateX(-50%);
    }

    .exchange-btn:nth-child(2) .play-to-earn-btn:before {
        height: 100px;
        left: 50%;
        width: 1px;
    }

    .exchange-btn-main:before {
        position: absolute;
        content: "";
        border: 1px dashed rgba(255, 255, 255, 1);
        height: 1px;
        width: 250.8px;
        top: -80%;
        transform: translateX(-50%);
        left: 50%;
    }

    .paly-to-earn .playnow-btn {
        width: 90%;
    }

        .paly-to-earn .playnow-btn a,
        .paly-to-earn .playnow-btn button {
            width: 100%
        }

    .core-team {
        padding: 100px 20px;
        position: relative;
        z-index: 9999;
    }

        .core-team p {
            width: 100%;
        }

    .coin-image:before {
        height: 211px;
        top: 3px;
        left: -30px;
        z-index: -9;
        width: 382px;
        background-size: cover;
    }

    /* .core-team-border img {
        max-width: 100%;
    }*/

    .core-team-details h6 {
        font-size: 15px;
        margin: 0;
    }

    .core-team-details span {
        font-size: 14px;
        line-height: 15px;
        letter-spacing: -0.02em;
    }

    .core-team-details {
        margin-top: 7px;
        padding-right: 12px;
    }

    .core-team-border:before {
        background: url(/public/mobile-coreTeam-border.png);
        height: 135px;
        width: 166px;
        top: 50%;
        background-size: cover !important;
        left: 50%;
    }

    .core-team-border:hover:before {
        background: url(/public/mobile-hover-coreTeam-border.png);
    }

    .roadmap-coin img {
        width: 43%;
    }

    .core-team:before {
        display: none;
    }

    .roadmap-coin {
        margin-top: 40px;
        text-align: right;
        margin-right: 70px;
    }

    .phase-details {
        position: relative;
        left: 0;
        bottom: 0;
        z-index: 99;
        top: -75px;
    }

    .PhaseHoverImage {
        opacity: 0;
        transition: all 300ms ease-in-out;
        -webkit-transition: all 300ms ease-in-out;
        position: absolute;
        z-index: 999;
        left: 0;
    }

    .nft-slider {
        max-width: 100%;
        margin: 0 auto;
        overflow-x:auto;
        margin-top:25px;
    }
       

    #nftartMobile .carousel-item {
        padding: 0 10px;
        max-width: 100%;
    }

    .nft-border {
        background-size: cover !important;
        height: 210px;
        width: 213px;
        margin: auto;

    }



        .nft-border img{
            width:100%;
        }
        .nft-border img:last-child {
            width: 86%;
            top: 50%;
        }

    .phase-images:hover .PhaseHoverImage {
        opacity: 1;
    }

    .roadmap-coin-main {
        padding: 50px 20px;
    }

    .phase-1,
    .phase-2,
    .phase-3,
    .phase-5,
    .phase-4 {
        position: relative;
        top: 0;
        left: 0;
        z-index: 999;
    }

    .phase-details span,
    .phase-images:after {
        display: none;
    }

    .phase-images img {
        width: 58%;
        position: relative;
        z-index: 9;
    }

    .phase-images:before {
        border: 1px solid #0BFFF0;
        left: 30%;
    }

    .phase-5:last-child .phase-images:before {
        height: 132px;
        top: -58%;
    }

    .phase-details p {
        display: none;
    }

    .moon-man {
        position: relative;
        width: 25%;
        right: unset;
        left: -3%;
        top: -80px;
    }

    .phase-details-box {
        background: linear-gradient(60deg, #FAD0C4 13.4%, #FBC2EB 86.6%);
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        padding: 5px 10px 0px 20px;
        width: 100%;
        position: relative;
        height: 70px;
        margin-top: 20px;
    }

        .phase-details-box h6 {
            font-size: 12px;
            width: 100%;
        }

        .phase-details-box:before {
            position: absolute;
            content: "";
            width: 0;
            height: 0;
            top: 0px;
            left: -20px;
            border-left: 20px solid transparent;
            border-right: 20px solid transparent;
            border-top: 20px solid #FAD0C4;
        }

        .phase-details-box h5 {
            font-weight: 900;
            font-size: 20px;
            letter-spacing: 0.04em;
            text-transform: uppercase;
            background: linear-gradient(0deg, #282828 0%, #6E2985 67%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            -webkit-text-fill-color: transparent;
            -webkit-text-stroke: 0.5px rgba(255, 240, 96, 0.5);
            margin-bottom: 5px;
        }

        .phase-details-box h6 {
            background: linear-gradient(0deg, #282828 0%, #6E2985 67%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            -webkit-text-fill-color: transparent;
            font-weight: 900;
            font-size: 13px;
            line-height: 15px;
            margin-bottom: 0;
            letter-spacing: -0.02em;
            text-transform: uppercase;
            width: 50%;
        }

    .phase-arrow {
        display: inline-block !important;
        top: 16%;
        left: -7.1%;
        border-bottom: 12px solid #0cfcef;
    }

    .footer-logo {
        width: 100%;
    }

    .playnow-btn a span,
    .playnow-btn button span {
        font-size: 25px;
    }

    .mint-now {
        padding-right: 0px !important;
    }

    footer {
        padding: 0 35px 85px 35px;
    }

    .footer-btn.playnow-btn {
        width: 100%;
        margin-left: 0px;
    }

    .last-btn .playnow-btn {
        margin: 0;
        width: 100%;
    }

        .last-btn .playnow-btn a,
        .footer-btn.playnow-btn a,
        .last-btn .playnow-btn button,
        .footer-btn.playnow-btn button {
            padding: 2px 50px;
            width: 100%;
            border-radius: 15px;
        }

    .playnow-btn:before {
        border-radius: 15px;
        right: -4px;
        top: 5px;
    }

    .footer-menu.ul-border ul:before {
        display: none;
    }

    .footer-menu ul li {
        position: relative;
        width: 175px;
        height: 46px;
    }

        .footer-menu ul li a {
            font-size: 16px;
        }

        .footer-menu ul li:before {
            background-size: contain;
        }

    .footer-menu ul {
        padding: 0 0px;
        margin-top: 30px;
        margin-bottom: 0;
    }

    .coin-price {
        padding: 2px 30px 2px 60px;
        font-size: 24px;
    }

        .coin-price img {
            width: 13%;
        }

    .footer-community h6 {
        font-size: 20px;
    }

    .footer-community {
        padding: 10px;
    }

    .genesis h2 {
        width: 75%;
        margin: auto;
    }

    .genesis p {
        width: 80% !important;
    }

    .icc-coin {
        padding: 50px 0;
    }

    .mobile .menu-nav .navbar-nav li.nav-item:hover .dropdown {
        display: flex;
        top: -77px;
        opacity: 1;
        z-index: 999;
    }

    .mobile .dropdown {
        background: linear-gradient(180deg, #F3A6FF 0%, #B380F5 104.29%);
        border-radius: 10px;
        width: auto;
        top: -1000px;
        opacity: 0;
        padding: 3px;
        display: flex;
        z-index: 9;
        justify-content: space-around;
        left: 50%;
        transform: translateX(-50%);
    }

        .mobile .dropdown li a:before {
            display: none;
        }

        .mobile .dropdown li a {
            text-decoration: none !important;
        }

            .mobile .dropdown li a span {
                font-size: 12px;
                text-align: center;
                color: #FFFFFF;
                mix-blend-mode: normal;
                min-width: 54px;
                text-shadow: 0.985606px 0.985606px 1.47841px rgba(0, 0, 0, 0.15);
                transition: all 500ms ease-in-out;
            }

            .mobile .dropdown li a:hover span {
                color: #FBD241 !important;
            }

        .mobile .dropdown li a {
            padding: 0 10px;
        }

    .menu-nav .navbar-nav li.nav-item > a:hover {
        background: transparent;
        outline: none !important;
        color: #FBD241;
    }

    .menu-nav .navbar-nav li:hover .hover-icon {
        opacity: 1 !important;
        z-index: 999;
    }

    .mobile .dropdown li a img:nth-child(2) {
        transition: all 500ms ease-in-out;
        opacity: 0 !important;
        position: absolute;
    }

    .mobile .dropdown li a:hover img:nth-child(2) {
        opacity: 1 !important;
        z-index: 999;
    }

    .hover-icon {
        left: 0;
        top: 5px;
        opacity: 0 !important;
        position: absolute;
    }

    .pool-icon {
        left: 17px;
        top: 2px;
    }

    .farm-icon {
        top: 2px;
        left: 18px;
    }

    .trade-icon {
        left: 29px;
        top: 2px;
    }

    .liquid-icon {
        left: 23px;
        top: -1px;
    }

    .white-paper {
        left: 40px;
        top: 2px;
    }

    .white-footer-menu:before {
        width: 187px !important;
        height: 45px !important;
        left: 50% !important;
        transform: translateX(-50%);
    }
}

@media(max-width:395px) {
    .core-team {
        padding: 100px 10px;
        position: relative;
        z-index: 9999;
    }

    .phase-details-box h6 {
        font-size: 10px;
    }
}
/*@media(min-width:576px) and (max-width:767px) {
    .phase-details-box {
        display: block;
    }

    .slider {
        height: auto;
        padding: 50px 0 200px 0;
    }

    .landing-bg {
        background: url(/public/mobile-landing-page-bg.png);
        background-size: cover;
        background-repeat: no-repeat;
    }

    .side-social-icon {
        display: none;
    }

    .menu-navbar.desktop {
        display: none;
    }

    .menu-navbar.mobile .menu-nav {
        width: 100%;
    }

        .menu-navbar.mobile .menu-nav .navbar-nav {
            width: 100%;
            justify-content: space-around;
            padding: 3px 10px;
        }

    .menu-navbar.mobile {
        position: fixed;
        bottom: 20px;
        left: 20px;
        z-index: 99999;
        right: 20px;
        background: linear-gradient(180deg, #F3A6FF 0%, #B380F5 100%);
        border-radius: 10px;
    }

        .menu-navbar.mobile li.nav-item a {
            width: unset;
            transform: skewX(0deg);
            font-size: 13px;
        }

            .menu-navbar.mobile li.nav-item a span {
                transform: skewX(0deg);
                display: block;
            }

    .menu-navbar {
        padding: 0;
    }

    .slider-gif {
        width: 100%;
        height: 212px;
        border-radius: 30px;
        margin-top: 15px;
        border: 5px solid #FBC4E6;
    }

    .slider-logo {
        width: 100%;
        margin-top: 0px;
    }

    .slider-text img.slider-logo {
        margin-bottom: 10px;
    }

    .playnow-btn button {
        padding: 8px 50px;
    }

        .playnow-btn button span {
            font-size: 24px;
        }

    #inviteCode.invite-page:after,
    #inviteCode.invite-page #link {
        font-size: 12px;
    }

    #inviteCode.invite-page #link {
        margin-left: 70px;
    }

    #inviteCode.invite-page {
        width: 100%;
    }

    .slider-text h5 {
        margin-top: 20px;
    }

    .slider-text .playnow-btn {
        margin-top: 20px;
    }

    .game-play {
        padding: 50px 0;
        position: relative;
    }

    .section-heading {
        font-size: 30px;
        letter-spacing: 0.04em;
    }

    .subheading-text {
        width: 90%;
        font-size: 17px;
    }

    .gameplay-slider:before,
    .game-play:before {
        display: none;
    }

    .gameplay-slider .carousel-item h3 {
        text-align: center;
    }

    .gameplay-slider .carousel-item p {
        font-weight: 900;
        font-size: 18px;
        line-height: 24px;
        color: #0BFFF0;
        width: 60%;
        margin: auto;
    }

    .gameplay-slider .carousel-item p {
        width: 100%;
        text-align: center;
        margin-top: 20px;
    }

    .gameplay-slider .carousel-item .playnow-btn button {
        padding: 8px 60px;
        width: 100%;
    }

    .gameplay-slider .carousel-item .playnow-btn {
        margin-top: 80px;
        width: 90%;
        margin-left: 0px;
    }

    .prev-icon,
    .next-icon {
        background: transparent;
        border: none;
        padding: 0;
    }

    .gameplay-slider-btn {
        position: absolute;
        margin: 0;
        z-index: 999;
    }

        .gameplay-slider-btn.prev {
            left: 0%;
            bottom: 18%;
        }

        .gameplay-slider-btn.next {
            right: 0%;
            bottom: 18%;
        }

    .gameplay-slider .carousel-indicators [data-bs-target] {
        height: 1px;
        margin: 0 10px;
        width: 70px;
    }

    .gameplay-slider .carousel-indicators {
        position: absolute;
        right: unset;
        width: 100%;
        text-align: center;
        left: 50%;
        transform: translateX(-50%);
        bottom: 20%;
        z-index: 2;
    }

    .mint-now {
        margin: 15px 0 0;
    }

        .mint-now .playnow-btn button {
            width: 100%;
        }

        .mint-now .playnow-btn {
            margin: 0;
            width: 90%;
        }

    .nft-slider {
        padding: 0 10px;
    }

        .nft-slider.gameplay-slider .carousel-indicators,
        .gameplay-slider-btn.playnow-btn.next,
        .gameplay-slider-btn.playnow-btn.prev {
            display: none;
        }

    .coin {
        z-index: 9;
    }

    @keyframes coin {
        0% {
            top: 0px;
        }

        50% {
            top: 5px;
        }

        100% {
            top: -15px;
        }
    }

    .paly-to-earn {
        padding: 0;
    }

    .P2E-text {
        width: 50%;
    }

    .play-to-earn-btn {
        background: linear-gradient(60deg, #FAD0C4 13.4%, #FBC2EB 86.6%);
        border-radius: 10px;
        outline: 5px solid rgba(255, 255, 255, 0.1) !important;
        border: none;
        padding: 5px;
        position: relative;
        width: auto;
    }

        .play-to-earn-btn:before {
            position: absolute;
            content: "";
            height: 30px;
            width: 1px;
            border: 1px dashed rgba(255, 255, 255, 0.6);
            right: 50%;
            bottom: -30%;
            top: unset;
            transform: translateX(-50%);
        }

    .coin img {
        width: 32%;
    }

    .coin-image {
        position: relative;
        padding: 30px 0;
    }

    .exchange-btn-main {
        display: flex;
        justify-content: center;
    }

    .exchange-btn:nth-child(2) {
        margin: 0 15px;
    }

    .exchange-btn .play-to-earn-btn {
        padding: 5px;
        text-align: center;
        height: 130px;
        width: 110px;
    }

    .gift-img {
        margin-top: -20px;
    }

    .trade-img {
        margin-top: 0px;
    }

    .exchange-btn-main .play-to-earn-btn img:first-child {
        width: 100%;
    }

    .exchange-btn-main .play-to-earn-btn img:nth-child(2) {
        width: 100%;
        margin: 0 !important;
    }

    .exchange-btn .play-to-earn-btn:before {
        position: absolute;
        content: "";
        height: 100px;
        width: 1px;
        border: 1px dashed rgba(255, 255, 255, 1);
        left: 50%;
        top: -100px;
        transform: translateX(-50%);
    }

    .exchange-btn:nth-child(2) .play-to-earn-btn:before {
        height: 100px;
        left: 50%;
        width: 1px;
    }

    .exchange-btn-main:before {
        position: absolute;
        content: "";
        border: 1px dashed rgba(255, 255, 255, 1);
        height: 1px;
        width: 60.5%;
        top: -80%;
        transform: translateX(-50%);
        left: 50%;
    }

    .paly-to-earn .playnow-btn {
        width: 90%;
    }

        .paly-to-earn .playnow-btn button {
            width: 100%
        }

    .core-team {
        padding: 100px 30px;
        position: relative;
        z-index: 99;
    }

        .core-team p {
            width: 100%;
        }

    .coin-image:before {
        height: 211px;
        top: 3px;
        left: -30px;
        z-index: -9;
        width: 382px;
        background-size: cover;
    }

    .core-team-border img {
        width: 100%;
    }

    .core-team-details h6 {
        font-size: 15px;
        margin: 0;
    }

    .core-team-details span {
        font-size: 14px;
    }

    .core-team-details {
        margin-top: 7px;
        padding-right: 12px;
    }

    .core-team-border:before {
        height: 184px;
        width: 185px;
        top: 51%;
        background-size: cover !important;
        left: 50%;
    }

    .roadmap-coin img {
        width: 43%;
    }

    .core-team:before {
        display: none;
    }

    .roadmap-coin {
        margin-top: 40px;
        text-align: right;
        margin-right: 70px;
    }

    .phase-details {
        position: relative;
        left: 0;
        bottom: 0;
        z-index: 99;
        top: -75px;
    }

    .PhaseHoverImage {
        opacity: 0;
        transition: all 300ms ease-in-out;
        -webkit-transition: all 300ms ease-in-out;
        position: absolute;
        z-index: 999;
        left: 0;
    }

    .phase-images:hover .PhaseHoverImage {
        opacity: 1;
    }

    .roadmap-coin-main {
        padding: 150px 20px;
    }

    .phase-1,
    .phase-2,
    .phase-3,
    .phase-5,
    .phase-4 {
        position: relative;
        top: 0;
        left: 0;
        z-index: 999;
    }

    .phase-details span,
    .phase-images:after {
        display: none;
    }

    .phase-images img {
        width: 58%;
        position: relative;
        z-index: 9;
    }

    .phase-images:before {
        border: 1px solid #0BFFF0;
        left: 30%;
    }

    .phase-5:last-child .phase-images:before {
        height: 132px;
        top: -58%;
    }

    .phase-details p {
        display: none;
    }

    .moon-man {
        position: relative;
        width: 25%;
        right: unset;
        left: -3%;
        top: -80px;
    }

    .phase-details-box {
        background: linear-gradient(60deg, #FAD0C4 13.4%, #FBC2EB 86.6%);
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        padding: 5px 20px 0px;
        width: 100%;
        position: relative;
        height: 70px;
        margin-top: 20px;
    }

        .phase-details-box:before {
            position: absolute;
            content: "";
            width: 0;
            height: 0;
            top: 0px;
            left: -20px;
            border-left: 20px solid transparent;
            border-right: 20px solid transparent;
            border-top: 20px solid #FAD0C4;
        }

        .phase-details-box h5 {
            font-weight: 900;
            font-size: 20px;
            letter-spacing: 0.04em;
            text-transform: uppercase;
            background: linear-gradient(0deg, #282828 0%, #6E2985 67%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            -webkit-text-fill-color: transparent;
            -webkit-text-stroke: 0.5px rgba(255, 240, 96, 0.5);
            margin-bottom: 5px;
        }

        .phase-details-box h6 {
            background: linear-gradient(0deg, #282828 0%, #6E2985 67%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            -webkit-text-fill-color: transparent;
            font-weight: 900;
            font-size: 13px;
            line-height: 15px;
            margin-bottom: 0;
            letter-spacing: -0.02em;
            text-transform: uppercase;
            width: 50%;
        }

    .phase-arrow {
        display: inline-block !important;
        top: 16%;
        left: -7.1%;
        border-bottom: 12px solid #0cfcef;
    }

    .footer-logo {
        width: 100%;
    }

    footer {
        padding: 0 30px 85px 30px;
    }

    .footer-btn.playnow-btn {
        width: 84%;
        margin-left: 0px;
    }

    .last-btn .playnow-btn {
        margin: 0;
        width: 84%;
    }

        .last-btn .playnow-btn button,
        .footer-btn.playnow-btn button {
            padding: 1px 50px;
            width: 100%;
            border-radius: 15px;
        }

    .playnow-btn:before {
        border-radius: 15px;
    }

    .footer-menu.ul-border ul:before {
        display: none;
    }

    .footer-menu ul li {
        position: relative;
        width: 180px;
        height: 60px;
    }

        .footer-menu ul li a {
            font-size: 18px;
        }

        .footer-menu ul li:before {
            background-size: contain;
        }

    .footer-menu ul {
        padding: 0 0px;
        margin-top: 30px;
        margin-bottom: 0;
    }

    .coin-price {
        padding: 2px 30px 2px 60px;
        font-size: 24px;
    }

        .coin-price img {
            width: 13%;
        }

    .footer-community h6 {
        font-size: 20px;
    }

    .footer-community {
        padding: 10px;
    }

    .genesis h2 {
        width: 75%;
        margin: auto;
    }

    .genesis p {
        width: 70%;
    }

    .icc-coin {
        padding: 75px 0;
    }

    .mobile .menu-nav .navbar-nav li.nav-item:hover .dropdown {
        display: flex;
        top: -77px;
        opacity: 1;
    }

    .mobile .dropdown {
        background: linear-gradient(180deg, #F3A6FF 0%, #B380F5 104.29%);
        border-radius: 10px;
        width: auto;
        top: -500px;
        opacity: 0;
        padding: 3px;
        display: flex;
        justify-content: space-around;
        left: 50%;
        transform: translateX(-50%);
    }

        .mobile .dropdown li a:before {
            display: none;
        }

        .mobile .dropdown li a {
            text-decoration: none !important;
        }

            .mobile .dropdown li a span {
                font-size: 12px;
                text-align: center;
                color: #FFFFFF;
                mix-blend-mode: normal;
                min-width: 54px;
                text-shadow: 0.985606px 0.985606px 1.47841px rgba(0, 0, 0, 0.15);
                transition: all 500ms ease-in-out;
            }

            .mobile .dropdown li a:hover span {
                color: #FBD241 !important;
            }

        .mobile .dropdown li a {
            padding: 0 10px;
        }

    .menu-nav .navbar-nav li.nav-item > a:hover {
        background: transparent;
        outline: none !important;
        color: #FBD241;
    }

    .menu-nav .navbar-nav li:hover .hover-icon {
        opacity: 1 !important;
        z-index: 999;
    }

    .mobile .dropdown li a img:nth-child(2) {
        transition: all 500ms ease-in-out;
        opacity: 0 !important;
        position: absolute;
    }

    .mobile .dropdown li a:hover img:nth-child(2) {
        opacity: 1 !important;
        z-index: 999;
    }

    .hover-icon {
        left: 0;
        top: 5px;
        opacity: 0 !important;
        position: absolute;
    }

    .pool-icon {
        left: 17px;
        top: 2px;
    }

    .farm-icon {
        top: 2px;
        left: 18px;
    }

    .trade-icon {
        left: 29px;
        top: 2px;
    }

    .liquid-icon {
        left: 23px;
        top: -1px;
    }

    .white-paper {
        left: 40px;
        top: 2px;
    }
}*/

@media(min-width:768px) and (max-width:991px) {
    .nft-slider {
        max-width: 100%;
        margin: 0 auto;
        overflow-x: auto;
        margin-top: 25px;
    }
    .nft-border img {
        width: 100%;
    }
    .nft-slider .row {
        width: 2670px;
        margin: 0 70px;
    }
    .nft-border {
        height: 256px;
        width: 260px;
    }
        .nft-border img:last-child {
            width: 90%;
        }
    .white-footer-menu:before {
        width: 187px !important;
        height: 45px !important;
        left: 50% !important;
        transform: translateX(-50%);
    }

    .slider {
        height: auto;
        padding: 50px 0 200px 0;
    }

    .landing-bg {
        background: url(/public/mobile-landing-page-bg.png);
        background-size: cover;
        background-repeat: no-repeat;
    }

    .side-social-icon {
        display: none;
    }

    .menu-navbar.desktop {
        display: none;
    }

    .menu-navbar.mobile .menu-nav {
        width: 100%;
    }

        .menu-navbar.mobile .menu-nav .navbar-nav {
            width: 100%;
            justify-content: space-around;
            padding: 3px 10px;
        }

    .menu-navbar.mobile {
        position: fixed;
        bottom: 20px;
        left: 20px;
        z-index: 99999;
        right: 20px;
        background: linear-gradient(180deg, #F3A6FF 0%, #B380F5 100%);
        border-radius: 10px;
    }

        .menu-navbar.mobile li.nav-item a {
            width: unset;
            transform: skewX(0deg);
            font-size: 13px;
        }

            .menu-navbar.mobile li.nav-item a span {
                transform: skewX(0deg);
                display: block;
            }

    .menu-navbar {
        padding: 0;
    }

    .slider-gif {
        width: 100%;
        /* height: 212px; */
        height: 58%;
        border-radius: 30px;
        margin-top: 15px;
        border: 5px solid #FBC4E6;
    }

    .slider-logo {
        width: 100%;
        margin-top: 0px;
    }

    .slider-text img.slider-logo {
        margin-bottom: 10px;
    }

    .playnow-btn a,
    .playnow-btn button {
        padding: 8px 50px;
    }

        .playnow-btn a span,
        .playnow-btn button span {
            font-size: 24px;
        }

    #inviteCode.invite-page:after,
    #inviteCode.invite-page #link {
        font-size: 12px;
    }

    #inviteCode.invite-page #link {
        padding-left: 70px;
    }

    #inviteCode.invite-page {
        width: 100%;
    }

    .slider-text h5 {
        margin-top: 20px;
    }

    .slider-text .playnow-btn {
        margin-top: 20px;
    }

    .game-play {
        padding: 50px 0;
        position: relative;
    }

    .section-heading {
        font-size: 30px;
        letter-spacing: 0.04em;
    }

    .subheading-text {
        width: 90%;
        font-size: 17px;
    }

    .gameplay-slider:before,
    .game-play:before {
        display: none;
    }

    .gameplay-slider .carousel-item h3 {
        text-align: center;
    }

    .gameplay-slider .carousel-item p {
        font-weight: 900;
        font-size: 18px;
        line-height: 24px;
        color: #0BFFF0;
        width: 60%;
        margin: auto;
    }

    .gameplay-slider .carousel-item p {
        width: 100%;
        text-align: center;
        margin-top: 20px;
    }

    .gameplay-slider .carousel-item .playnow-btn a,
    .gameplay-slider .carousel-item .playnow-btn button {
        padding: 8px 60px;
        width: 100%;
    }

    .gameplay-slider .carousel-item .playnow-btn {
        margin-top: 80px;
        width: 90%;
        margin-left: 0px;
    }

    .prev-icon,
    .next-icon {
        background: transparent;
        border: none;
        padding: 0;
    }

    .gameplay-slider-btn {
        position: absolute;
        margin: 0;
        z-index: 999;
    }

        .gameplay-slider-btn.prev {
            left: 0%;
            bottom: 18%;
        }

        .gameplay-slider-btn.next {
            right: 0%;
            bottom: 18%;
        }

    .gameplay-slider .carousel-indicators [data-bs-target] {
        height: 1px;
        margin: 0 10px;
        width: 70px;
    }

    .gameplay-slider .carousel-indicators {
        position: absolute;
        right: unset;
        width: 100%;
        text-align: center;
        left: 50%;
        transform: translateX(-50%);
        bottom: 20%;
        z-index: 2;
    }

    .mint-now {
        margin: 15px 0 0;
    }

        .mint-now .playnow-btn a,
        .mint-now .playnow-btn button {
            width: 100%;
        }

        .mint-now .playnow-btn {
            margin: 0;
            width: 90%;
        }

    .nft-slider {
        padding: 0 10px;
    }

        .nft-slider.gameplay-slider .carousel-indicators,
        .gameplay-slider-btn.playnow-btn.next,
        .gameplay-slider-btn.playnow-btn.prev {
            display: none;
        }

    .coin {
        z-index: 9;
    }

    @keyframes coin {
        0% {
            top: 0px;
        }

        50% {
            top: 5px;
        }

        100% {
            top: -15px;
        }
    }

    .paly-to-earn {
        padding: 0;
    }

    .P2E-text {
        width: 50%;
    }

    .play-to-earn-btn {
        background: linear-gradient(60deg, #FAD0C4 13.4%, #FBC2EB 86.6%);
        border-radius: 10px;
        outline: 5px solid rgba(255, 255, 255, 0.1) !important;
        border: none;
        padding: 5px;
        position: relative;
        width: auto;
    }

        .play-to-earn-btn:before {
            position: absolute;
            content: "";
            height: 30px;
            width: 1px;
            border: 1px dashed rgba(255, 255, 255, 0.6);
            right: 50%;
            bottom: -30%;
            top: unset;
            transform: translateX(-50%);
        }

    .coin img {
        width: 32%;
    }

    .coin-image {
        position: relative;
        padding: 30px 0;
    }

    .exchange-btn-main {
        display: flex;
        justify-content: center;
    }

    .exchange-btn:nth-child(2) {
        margin: 0 65px;
    }

    .exchange-btn .play-to-earn-btn {
        padding: 5px;
        text-align: center;
        height: 130px;
        width: 110px;
    }

    .gift-img {
        margin-top: -20px;
    }

    .trade-img {
        margin-top: 0px;
    }

    .exchange-btn-main .play-to-earn-btn img:first-child {
        width: 100%;
    }

    .exchange-btn-main .play-to-earn-btn img:nth-child(2) {
        width: 100%;
        margin: 0 !important;
    }

    .exchange-btn .play-to-earn-btn:before {
        position: absolute;
        content: "";
        height: 100px;
        width: 1px;
        border: 1px dashed rgba(255, 255, 255, 1);
        left: 50%;
        top: -100px;
        transform: translateX(-50%);
    }

    .exchange-btn:nth-child(2) .play-to-earn-btn:before {
        height: 100px;
        left: 50%;
        width: 1px;
    }

    .exchange-btn-main:before {
        position: absolute;
        content: "";
        border: 1px dashed rgba(255, 255, 255, 1);
        height: 1px;
        width: 349px;
        top: -80%;
        transform: translateX(-50%);
        left: 50%;
    }

    .paly-to-earn .playnow-btn {
        width: 90%;
    }

        .paly-to-earn .playnow-btn a,
        .paly-to-earn .playnow-btn button {
            width: 100%
        }

    .core-team {
        padding: 100px 30px;
        position: relative;
        z-index: 99;
    }

        .core-team p {
            width: 100%;
        }


    .core-team-details h6 {
        font-size: 15px;
        margin: 0;
    }

    .core-team-details span {
        font-size: 14px;
    }

    .core-team-details {
        margin-top: 7px;
        padding-right: 12px;
    }

    .core-team-border:before {
        height: 262px;
        width: 264px;
        top: 51%;
        background-size: cover !important;
        left: 50%;
    }

    .roadmap-coin img {
        width: 43%;
    }

    .core-team:before {
        display: none;
    }

    .roadmap-coin {
        margin-top: 40px;
        text-align: right;
        margin-right: 70px;
    }

    .phase-details {
        position: relative;
        left: 0;
        bottom: 0;
        z-index: 99;
        top: -75px;
    }

    .roadmap-coin-main {
        padding: 150px 20px;
    }

    .phase-1,
    .phase-2,
    .phase-3,
    .phase-5,
    .phase-4 {
        position: relative;
        top: 0;
        left: 0;
        z-index: 999;
    }

    .phase-details span,
    .phase-images:after {
        display: none;
    }

    .phase-images img {
        width: 58%;
        position: relative;
        z-index: 9;
    }

    .PhaseHoverImage {
        opacity: 0;
        transition: all 300ms ease-in-out;
        -webkit-transition: all 300ms ease-in-out;
        position: absolute !important;
        z-index: 999;
        left: 0;
    }

    .phase-images:hover .PhaseHoverImage {
        opacity: 1;
    }

    .phase-details-box {
        display: block;
    }

    .phase-images:before {
        border: 1px solid #0BFFF0;
        left: 30%;
    }

    .phase-5:last-child .phase-images:before {
        height: 132px;
        top: -58%;
    }

    .phase-details p {
        display: none;
    }

    .moon-man {
        position: relative;
        width: 25%;
        right: unset;
        left: -3%;
        top: -80px;
    }

    .phase-details-box {
        background: linear-gradient(60deg, #FAD0C4 13.4%, #FBC2EB 86.6%);
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        padding: 5px 20px 0px;
        width: 100%;
        position: relative;
        height: 70px;
        margin-top: 20px;
    }

        .phase-details-box:before {
            position: absolute;
            content: "";
            width: 0;
            height: 0;
            top: 0px;
            left: -20px;
            border-left: 20px solid transparent;
            border-right: 20px solid transparent;
            border-top: 20px solid #FAD0C4;
        }

        .phase-details-box h5 {
            font-weight: 900;
            font-size: 20px;
            letter-spacing: 0.04em;
            text-transform: uppercase;
            background: linear-gradient(0deg, #282828 0%, #6E2985 67%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            -webkit-text-fill-color: transparent;
            -webkit-text-stroke: 0.5px rgba(255, 240, 96, 0.5);
            margin-bottom: 5px;
        }

        .phase-details-box h6 {
            background: linear-gradient(0deg, #282828 0%, #6E2985 67%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            -webkit-text-fill-color: transparent;
            font-weight: 900;
            font-size: 13px;
            line-height: 15px;
            margin-bottom: 0;
            letter-spacing: -0.02em;
            text-transform: uppercase;
            width: 50%;
        }

    .phase-arrow {
        display: inline-block !important;
        top: 16%;
        left: -7.1%;
        border-bottom: 12px solid #0cfcef;
    }

    .footer-logo {
        width: 100%;
    }

    footer {
        padding: 0 30px 85px 30px;
    }

    .footer-btn.playnow-btn {
        width: 84%;
        margin-left: 0px;
    }

    .last-btn .playnow-btn {
        margin: 0;
        width: 84%;
    }

        .last-btn .playnow-btn a,
        .footer-btn.playnow-btn a ,
        .last-btn .playnow-btn button,
        .footer-btn.playnow-btn button {
            padding: 1px 50px;
            width: 100%;
            border-radius: 15px;
        }

    .playnow-btn:before {
        border-radius: 15px;
    }

    .footer-menu.ul-border ul:before {
        display: none;
    }

    .footer-menu ul li {
        position: relative;
        width: 180px;
        height: 60px;
    }

        .footer-menu ul li a {
            font-size: 18px;
        }

        .footer-menu ul li:before {
            background-size: contain;
        }

    .footer-menu ul {
        padding: 0 0px;
        margin-top: 30px;
        margin-bottom: 0;
    }

    .coin-price {
        padding: 2px 30px 2px 60px;
        font-size: 24px;
    }

        .coin-price img {
            width: 13%;
        }

    .footer-community h6 {
        font-size: 20px;
    }

    .footer-community {
        padding: 10px;
    }

    .genesis h2 {
        width: 75%;
        margin: auto;
    }

    .genesis p {
        width: 70%;
    }

    .icc-coin {
        padding: 75px 0;
    }

    .mobile .menu-nav .navbar-nav li.nav-item:hover .dropdown {
        display: flex;
        top: -77px;
        opacity: 1;
    }

    .mobile .dropdown {
        background: linear-gradient(180deg, #F3A6FF 0%, #B380F5 104.29%);
        border-radius: 10px;
        width: auto;
        top: -500px;
        opacity: 0;
        padding: 3px;
        display: flex;
        justify-content: space-around;
        left: 50%;
        transform: translateX(-50%);
    }

        .mobile .dropdown li a:before {
            display: none;
        }

        .mobile .dropdown li a {
            text-decoration: none !important;
        }

            .mobile .dropdown li a span {
                font-size: 12px;
                text-align: center;
                color: #FFFFFF;
                mix-blend-mode: normal;
                min-width: 54px;
                text-shadow: 0.985606px 0.985606px 1.47841px rgba(0, 0, 0, 0.15);
                transition: all 500ms ease-in-out;
            }

            .mobile .dropdown li a:hover span {
                color: #FBD241 !important;
            }

        .mobile .dropdown li a {
            padding: 0 10px;
        }

    .menu-nav .navbar-nav li.nav-item > a:hover {
        background: transparent;
        outline: none !important;
        color: #FBD241;
    }

    .menu-nav .navbar-nav li:hover .hover-icon {
        opacity: 1 !important;
        z-index: 999;
    }

    .mobile .dropdown li a img:nth-child(2) {
        transition: all 500ms ease-in-out;
        opacity: 0 !important;
        position: absolute;
    }

    .mobile .dropdown li a:hover img:nth-child(2) {
        opacity: 1 !important;
        z-index: 999;
    }

    .hover-icon {
        left: 0;
        top: 5px;
        opacity: 0 !important;
        position: absolute;
    }

    .pool-icon {
        left: 17px;
        top: 2px;
    }

    .farm-icon {
        top: 2px;
        left: 18px;
    }

    .trade-icon {
        left: 29px;
        top: 2px;
    }

    .liquid-icon {
        left: 23px;
        top: -1px;
    }

    .white-paper {
        left: 40px;
        top: 2px;
    }

    .landing-page {
        max-width: 600px;
        width: 100%;
        padding-right: var(--bs-gutter-x,.75rem);
        padding-left: var(--bs-gutter-x,.75rem);
        margin-right: auto;
        margin-left: auto;
    }
}

@media(min-width:992px) and (max-width:1199px) {
    #inviteCode.invite-page #link {
        font-size: 14px;
        padding-left: 100px;
        padding-right: 50px;
    }

    .slider {
        height: unset;
    }

    .gameplay-slider:before {
        right: 0px;
    }

    footer {
        padding: 0 0px 60px 0;
    }

    .footer-logo {
        max-width: 100%;
    }

    .last-btn .playnow-btn a,
    .last-btn .playnow-btn button {
        padding: 7px 36px;
        border-radius: 15px;
        width: 100%;
    }

    .footer-btn.playnow-btn {
        width: 100%;
        margin-left: 0px;
    }

    .two-buttons {
        display: flex !important;
    }

    .last-btn .playnow-btn a span,
    .last-btn .playnow-btn button span {
        font-size: 22px !important;
    }

    .gameplay-slider .carousel-item h3 {
        font-weight: 900;
        font-size: 32px;
        line-height: 24px;
        color: #0BFFF0;
        margin-top: 30px;
    }

    .footer-menu ul {
        padding: 0 40px;
    }

    .landing-page {
        max-width: 960px;
        width: 100%;
        padding-right: var(--bs-gutter-x,.75rem);
        padding-left: var(--bs-gutter-x,.75rem);
        margin-right: auto;
        margin-left: auto;
    }

    .core-team:before {
        max-width: 900px;
        top: 200px;
        height: 500px;
    }

    .menu-navbar {
        padding: 30px 50px 30px 20px;
    }

    .menu-nav .navbar-nav li.nav-item > a {
        width: 100px;
        font-size: 18px;
    }

    .playnow-btn a,
    .playnow-btn button {
        padding: 6px 20px;
        border-radius: 10px
    }

        .playnow-btn a span,
        .playnow-btn button span {
            font-size: 22px;
        }

    .playnow-btn:before {
        border-radius: 10px
    }

    .slider-text img.slider-logo {
        max-width: 100%;
    }

    .slider-gif {
        width: 500px;
        /* height: 500px; */
        height: 290px;
        border: 5px solid #FBC4E6;
        border-radius: 25px;
    }

    .section-heading {
        font-size: 50px;
    }

    .subheading-text {
        font-size: 16px;
        width: 65%;
    }

    .gameplay-slider .carousel-item p {
        font-size: 16px;
        color: #0BFFF0;
        width: 100%;
    }

    .gameplay-slider .carousel-indicators [data-bs-target] {
        background: linear-gradient(60deg, #FAD0C4 13.4%, #FBC2EB 86.6%);
        box-shadow: 4px 4px 0px #c445ab;
        border-radius: 5px;
        height: 6px;
        opacity: 1;
        margin: 0 10px;
        width: 100px;
    }

    .mint-now {
        margin: 20px 0 0;
    }

    .nft-border img {
        width: 100%;
    }

        .nft-border img:last-child {
            width: 90%;
        }

    .nft-border {
        background-size: cover !important;
        height: 169px;
        width: 180px;
    }

    .nft-slider {
        padding: 0px;
    }

        .nft-slider .gameplay-slider-btn.playnow-btn.prev {
            left: 2%;
            bottom: -24%;
        }

        .nft-slider .gameplay-slider-btn.playnow-btn.next {
            right: 2%;
            bottom: -24%;
        }

        .nft-slider.gameplay-slider .carousel-indicators {
            bottom: -20%;
        }

    .paly-to-earn {
        padding: 0 50px;
    }

    .play-to-earn-btn {
        padding: 10px;
        border-radius: 25px;
    }

        .play-to-earn-btn img {
            width: 45%;
        }

    .exchange-btn .play-to-earn-btn img {
        width: 44%;
    }

    .coin img {
        width: 40%;
        margin-right: 50px;
        position: relative;
        z-index: 9;
    }

    .coin-image:before {
        height: 300px;
        background-size: cover;
        z-index: -9;
        width: 500px;
    }

    .core-team {
        padding: 200px 30px;
    }

    .core-team-border:before {
        height: 298px;
        width: 300px;
        left: 49.7%;
        background-size: cover !important;
    }

    .phase-images:after {
        width: 151px;
        height: 67px;
        background-size: cover;
        top: -55px;
        left: 40px;
    }

    .core-team-border img {
        width: 100%;
    }

    .core-team-details h6 {
        font-size: 16.6108px;
    }

    .phase-details {
        left: 0;
    }

    .phase-images img {
        width: 60px;
    }

    .phase-details p {
        width: 85%;
        margin: 10px 0 0 25px;
    }

    .roadmap-coin-main {
        padding: 300px 30px 270px;
    }

    .phase-1,
    .phase-2,
    .phase-3,
    .phase-5,
    .phase-4 {
        position: relative;
        top: 0;
        left: 0;
        z-index: 999;
    }

    .phase-details span,
    .phase-images:after {
        display: none;
    }

    .phase-images img {
        width: 58%;
        position: relative;
        z-index: 9;
    }

    .phase-images:before {
        border: 1px solid #0BFFF0;
        left: 30%;
    }

    .phase-5:last-child .phase-images:before {
        height: 132px;
        top: -58%;
    }

    .phase-details p {
        display: none;
    }

    .moon-man {
        position: relative;
        width: 15%;
        right: unset;
        left: -3%;
        top: -80px;
    }

    .phase-details-box {
        background: linear-gradient(60deg, #FAD0C4 13.4%, #FBC2EB 86.6%);
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        padding: 5px 20px 0px;
        width: 90%;
        position: relative;
        height: 70px;
        margin-top: 20px;
    }

        .phase-details-box:before {
            position: absolute;
            content: "";
            width: 0;
            height: 0;
            top: 0px;
            left: -20px;
            border-left: 20px solid transparent;
            border-right: 20px solid transparent;
            border-top: 20px solid #FAD0C4;
        }

        .phase-details-box h5 {
            font-weight: 900;
            font-size: 20px;
            letter-spacing: 0.04em;
            text-transform: uppercase;
            background: linear-gradient(0deg, #282828 0%, #6E2985 67%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            -webkit-text-fill-color: transparent;
            -webkit-text-stroke: 0.5px rgba(255, 240, 96, 0.5);
            margin-bottom: 5px;
        }

        .phase-details-box h6 {
            background: linear-gradient(0deg, #282828 0%, #6E2985 67%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            -webkit-text-fill-color: transparent;
            font-weight: 900;
            font-size: 13px;
            line-height: 15px;
            margin-bottom: 0;
            letter-spacing: -0.02em;
            text-transform: uppercase;
            margin: 0 20px;
        }

    .phase-details-box {
        display: flex;
        align-items: center;
    }

    .phase-arrow {
        display: inline-block !important;
        top: 29%;
        left: 33px;
        border-bottom: 12px solid #0cfcef;
        margin: 0 !important;
    }

    .roadmap-coin img {
        width: 43%;
    }

    .roadmap-coin {
        margin-top: 0px;
        opacity: .4;
    }
}

@media (min-width: 1200px) {
    .col-xl {
        flex: 1 0 0% !important;
    }
}

@media(min-width:1200px) and (max-width:1399px) {
    .core-team:before {
        max-width: 1140px;
    }

    .slider-text img.slider-logo {
        max-width: 100%;
    }

    .slider.py-lg-5.px-xl-4.mx-xl-5 {
        margin-right: 0 !important;
        margin-left: 0 !important;
    }

    .slider-gif {
        width: 100%;
        /* height: 75%; */
        height: 58%;
    }

    .slider {
        height: auto;
    }

    .footer-logo {
        max-width: 250px;
    }

    footer {
        padding: 0 0px 60px 50px;
        position: relative;
        z-index: 99999;
    }

    .footer-menu ul {
        padding: 0 30px;
    }

        .footer-menu ul li a {
            font-size: 20px;
        }

        .footer-menu ul li {
            position: relative;
            width: 181px;
            height: 45px;
        }

    .footer-community img {
        width: 70%;
    }

    .footer-community {
        padding: 12px 10px;
    }

    .footer-btn.playnow-btn {
        width: 90%;
        margin-left: 0px;
    }

    .roadmap-coin-main {
        padding: 200px 30px 0px;
    }

    .phase-details {
        left: 0;
        bottom: 0;
    }

    .phase-images {
        width: 80px;
    }

        .phase-images img {
            width: 100%;
        }

        .phase-images:after {
            background-size: cover;
            width: 161px;
            height: 107px;
            top: -75px;
        }

    .phase-details p {
        font-size: 12px;
        margin: 10px 0 0 15px;
        width: 55%;
    }

    .phase-details span {
        font-weight: 800;
        font-size: 20px;
        margin-bottom: 60px;
        margin-left: 35px;
    }

    .phase-1 {
        position: absolute;
    }

    .phase-2 {
        top: -108px;
        left: 190px;
        position: absolute;
    }

    .phase-3 {
        top: -215px;
        left: 366px;
        position: absolute;
    }

    .phase-4 {
        top: -323px;
        left: 553px;
        position: absolute;
    }

    .phase-5 {
        top: -432px;
        left: 743px;
        position: absolute;
    }

    .roadmap-coin img {
        width: 400px;
        position: absolute;
    }

    .core-team {
        padding: 100px 50px;
        position: relative;
        z-index: 99;
    }

    .phase-details {
        position: relative;
        height: 400px;
    }

    .moon-man {
        right: -87%;
    }

    .core-team-border img {
        width: 100%;
    }

    .core-team-border:before {
        height: 190px !important;
        width: 191px !important;
        top: 51%;
        left: 50.4%;
        background-size: cover !important;
    }

    .core-team-details h6 {
        font-weight: 900;
        font-size: 18px;
    }

    .core-team-details span {
        font-size: 15px;
    }

    .paly-to-earn {
        padding: 0 30px;
    }

    .play-to-earn-btn img {
        width: 47%;
    }

    .exchange-btn .play-to-earn-btn img {
        width: 46%;
    }

    .play-to-earn-btn {
        border-radius: 30px;
    }

    .nft-border img{
        width:100%;
    }
    .nft-border img:last-child {
        width: 85%;
    }

    .nft-border {
        background-size: cover !important;
        height: 212px;
        width: 230px;
    }

    .nft-slider {
        padding: 0 30px;
    }

    .mint-now {
        margin-top: 25px;
    }

    .nft-slider .gameplay-slider-btn.playnow-btn.next {
        right: 2%;
        bottom: -20%;
    }

    .nft-slider .gameplay-slider-btn.playnow-btn.prev {
        left: 2%;
        bottom: -20%;
    }

    .nft-slider.gameplay-slider .carousel-indicators {
        bottom: -17%;
    }

    .gameplay-slider .carousel-item p {
        width: 100%;
    }

    .menu-nav .navbar-nav li.nav-item > a {
        width: 120px;
    }

    .playnow-btn a ,
    .playnow-btn button {
        padding: 8px 30px;
    }

        .playnow-btn a span ,
        .playnow-btn button span {
            transform: skew(40deg);
            font-weight: 900;
            font-size: 26px;
            text-align: center;
            display: inline-block;
            text-transform: uppercase;
            color: #FFFFFF;
        }

    .phase-main {
        padding-left: 200px;
        padding-top: 500px;
    }

    .gameplay-slider:before {
        right: 0;
    }

    .about-text {
        font-size: 19px;
        line-height: 24px;
    }

    .phase-arrow {
        margin: 0 !important;
        top: -33%;
        left: 45.2%;
    }

    .slider-text img.slider-logo {
        margin-bottom: 20px;
    }

    .slider-text h5 {
        margin-top: 20px;
    }

    .game-play {
        padding: 180px 0;
        position: relative;
    }
    .footer-last {
        padding-right: 70px;
    }
}

@media(min-width:1400px) and (max-width:1700px) {
    .slider-gif {
        width: 700px;
        /* height: 573.12px; */
        height: 400px;
    }

    .slider-text img.slider-logo {
        width: 100%;
        margin-bottom: 30px;
    }

    .slider-text h5 {
        margin-top: 20px;
    }

    .menu-navbar {
        padding: 50px;
    }

    .playnow-btn button {
        border-radius: 15px;
    }

        .playnow-btn a span,
        .playnow-btn button span {
            font-size: 28px;
        }

    .nft-border {
        height: 232px;
        width: 250px;
    }

    .nft-slider {
        padding: 0 50px;
        width: 1400px;
        margin: 0 auto;
    }
    .footer-last {
        padding-right: 70px;
    }
    .nft-border img{
        width:100%;
    }
    .nft-border img:last-child {
        width: 88%!important;
    }

    .mint-now {
        padding-right: 25px;
    }

    .nft-slider.gameplay-slider .carousel-indicators,
    .nft-slider .gameplay-slider-btn.playnow-btn.next,
    .nft-slider .gameplay-slider-btn.playnow-btn.prev {
        bottom: -15%;
        bottom: -15%;
    }

    .paly-to-earn {
        padding: 0 75px;
    }

    .play-to-earn-btn img {
        width: 47%;
    }

    .exchange-btn .play-to-earn-btn img {
        width: 45%;
    }

    .play-to-earn-btn {
        border-radius: 30px;
    }

    .icc-coin {
        padding: 100px 0 0;
    }

    .core-team-border img {
        width: 100%;
    }

    .core-team > .row {
        width: 1340px;
        margin: auto;
    }

    .core-team {
        padding: 200px 30px;
    }

    .core-team-border:before {
        background-size: cover !important;
        height: 230px;
        width: 231px;
    }

    .roadmap-coin img {
        width: 390px;
    }

    .phase-main {
        padding-left: 200px;
        padding-top: 20px;
    }

    .phase-details {
        position: relative;
        left: 0;
        bottom: 0;
        z-index: 99;
        height: 200px;
    }

    .phase-5,
    .phase-4,
    .phase-3,
    .phase-2,
    .phase-1 {
        position: absolute;
    }

    .phase-images {
        width: 80px;
    }

        .phase-images img {
            width: 100%;
            position: relative;
            z-index: 999;
        }

        .phase-images:after {
            background-size: cover;
            width: 211px;
            height: 107px;
            top: -75px;
        }

    .phase-details p {
        font-size: 14px;
        margin: 10px 0 0 15px;
        width: 36%;
    }

    .phase-details span {
        font-weight: 800;
        font-size: 20px;
        margin-bottom: 60px;
        margin-left: 35px;
    }

    .phase-1 {
        position: absolute;
    }

    .phase-2 {
        top: -110px;
        left: 250px;
        position: absolute;
    }

    .phase-3 {
        top: -220px;
        left: 481px;
        position: absolute;
    }

    .phase-4 {
        top: -331px;
        left: 723px;
        position: absolute;
    }

    .phase-5 {
        top: -441px;
        left: 967px;
        position: absolute;
    }

    .moon-man {
        position: absolute;
        top: -27%;
        right: -87%;
    }

    .footer-logo {
        width: 300px;
    }

    footer {
        padding: 0 0px 60px 40px;
    }

    .footer-menu ul {
        padding: 0 30px;
    }

    .gameplay-slider:before {
        right: 0;
    }
}

@media(min-width:1400px) and (max-width:1530px) {
    .footer-menu ul li {
        position: relative;
        width: 200px;
        height: 50px;
    }

        .footer-menu ul li a {
            font-size: 20px;
        }
}


@media(min-width:1900px){
    .slider-gif {
        width: 876.47px;
        height: 500px;
    }
}


@media(min-width:1701px) and (max-width:1899px) {
    .nft-slider {
        padding: 0 50px;
        width: 1700px;
        margin:0 auto;
    }

    .nft-border {
        height: 280px;
        width: 304px;
        background-size: cover !important;
    }

        .nft-border img {
            width: 100%;
        }

            .nft-border img:last-child {
                width: 85%;
            }

    .play-to-earn-btn {
        border-radius: 30px;
    }

        .play-to-earn-btn img {
            width: 48%;
        }

    .exchange-btn .play-to-earn-btn img {
        width: 47%;
    }

    .core-team > .row {
        width: 1640px;
        margin:0 auto;
    }

    .core-team {
        padding: 200px 30px;
    }

    .core-team-border img {
        width: 100%;
    }

    .roadmap-coin-main {
        overflow: hidden;
    }

    .slider-gif {
        width: 876.47px;
        /* height: 493.03px; */
        height: 500px;
        
    }

    .slider-text img.slider-logo {
        width: 100%;
    }
}

.mint-now {
    padding-right: 70px;
}

@media(max-width:419px) {
    .footer-menu ul li {
        width: 160px;
    }
    .footer-menu ul li {
        width: 136px;
        height: 40px;
    }

        .footer-menu ul li a {
            font-size: 12px;
        }

    .white-footer-menu:before {
        width: 136px !important;
        height: 40px !important;
        left: 50% !important;
        transform: translateX(-50%);
    }
}

.tooltip {
    position: relative;
    display: inline-block;
    opacity: 1;
}

    .tooltip button {
        background: transparent;
        border: none !important;
        padding: 0;
    }

    .tooltip .tooltiptext {
        visibility: hidden;
        width: 140px;
        background-color: rgba(0,0,0,.2);
        color: #fff;
        text-align: center;
        border-radius: 6px;
        padding: 5px;
        position: absolute;
        z-index: 1;
        bottom: 170%;
        left: 50%;
        margin-left: -70px;
        opacity: 0;
        transition: opacity 0.3s;
    }

        .tooltip .tooltiptext::after {
            content: "";
            position: absolute;
            top: 100%;
            left: 50%;
            margin-left: -5px;
            border-width: 5px;
            border-style: solid;
            border-color: rgba(0,0,0,.2) transparent transparent transparent;
        }

    .tooltip:hover .tooltiptext {
        visibility: visible;
        opacity: 1;
    }


@media(max-width:370px) {
    .exchange-btn:nth-child(2) {
        margin: 0 10px;
    }

    .exchange-btn-main:before {
        width: 240.8px;
    }
    .footer-btn.playnow-btn a span, .last-btn .playnow-btn a span,
    .footer-btn.playnow-btn button span, .last-btn .playnow-btn button span {
        font-size: 20px !important;
    }
    .footer-menu ul li {
        width: 136px;
        height: 40px;
    }
        .footer-menu ul li a {
            font-size: 12px;
        }
    .white-footer-menu:before {
        width: 136px !important;
        height: 40px !important;
        left: 50% !important;
        transform: translateX(-50%);
    }


}

@media(min-width:400px) and (max-width:414px) {
   

    .footer-menu ul li {
        width: 160px;
        height: 40px;
    }

        .footer-menu ul li a {
            font-size: 16px;
        }

    .white-footer-menu:before {
        width: 160px !important;
        height: 40px !important;
        left: 50% !important;
        transform: translateX(-50%);
    }
}
